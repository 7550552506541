.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
